<template>
    <div class="emb-gadget-wrap" v-if="loaded">        
        <v-card flat>
            <v-toolbar
                color="primary"
                dark
                extended
                flat
                >
                
                <v-row>
                    <v-col cols="4" xl="4" offset-xl="4" class="text-center align-middle">
                        <h5 class="white--text mt-14">LOCALES Y ZONAS DE REPARTO</h5>
                    </v-col>
                </v-row>
                <!-- <v-spacer></v-spacer> -->

            </v-toolbar> 

            <v-row>
                <v-col cols="12" xl="3" lg="3" md="12" sm="12" xs="12" style="height: calc(100vh - 65px);" class="secondary"> 
                    <div class="h-100" style="overflow-y: auto; overflow-x: hidden;">
                        <v-row>
                            <v-col cols="12" xl="12">
                                <v-card 
                                    color="primary"
                                    class="mb-4 ma-2"
                                    v-for="(row, index) in establishments"
                                    :key="index"
                                    hover
                                    dark
                                    @click="changeEstablishment(row.id)"
                                >
                                    <v-card-text>
                                        <h5 class="white--text">{{row.name}}</h5>
                                        <p class="white--text mb-1 pb-0">{{row.address}} - {{row.district}}</p>
                                        <p class="white--text mb-1 pb-0">Telf: {{row.telephone}}</p>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider class="my-4"></v-divider>
                </v-col>
                <v-col cols="12" xl="9" lg="9" md="12" sm="12" class="pt-3 hyo accent lighten-5" id="googlemapa">
                    <div style="height: calc(84vh - 110px);" >
                        
                    </div>
                </v-col>
            </v-row>
        </v-card>
	</div>
</template>

<script>
    import find from 'lodash/find';

    export default {
        data () {
            return {
                closed: false,
                loaded: false,
                resource: 'user/locals',
                establishments: [],
                establishment: {},
                latitude: null,
                longitude: null,
                coordinates: [],
                polygon: {},
                defaultCoords: [{'lat':-11.81550829492178,'lng':-77.13921132206916},{'lat':-11.8151722462836,'lng':-77.12410512089728},{'lat':-11.819120791739543,'lng':-77.11698117375373},{'lat':-11.821179053496568,'lng':-77.10805478215217},{'lat':-11.828571866049314,'lng':-77.09947171330451},{'lat':-11.835670458277718,'lng':-77.09827008366584},{'lat':-11.844868951553439,'lng':-77.09895672917365},{'lat':-11.845666979751881,'lng':-77.11371960759162},{'lat':-11.845792983991155,'lng':-77.13140072941779},{'lat':-11.838190624211101,'lng':-77.14178624272346}]
            }
        },
        async created() {
            // await this.initForm()
            await this.getEstablishment()
            await this.getRecord()
            await this.initMap()
        },
        methods: {
            async getEstablishment() {
                await this.$http.get(`/${this.resource}/establishments`).then(response => { 
                    this.establishments = response.data

                    this.establishment = this.establishments[0]
                })
            },
            async getRecord() {
                await this.$http.get(`/${this.resource}/record/${this.establishment.id}`).then(response => { 
                    if (response.data.data.id) {
                        this.coordinates = response.data.data.coordinates
                    } else {
                        this.coordinates = this.defaultCoords
                    }
                    this.loaded = true
                })
            },
            initMap() {
                this.latitude = this.establishment.latitude
                this.longitude = this.establishment.longitude

                let map = new window.google.maps.Map(document.getElementById('googlemapa'), {
                    center: new window.google.maps.LatLng(this.latitude, this.longitude),
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    zoomControl: false,
                    zIndex: 0,
                    zoom: 14,
                });

                let marker = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(this.latitude, this.longitude),
                    map: map,
                    // draggable: true
                    // icon: imagenSVG
                });

                this.polygon = new window.google.maps.Polygon({
                    paths: this.coordinates,
                    strokeColor: '#1a333c',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    fillColor: '#1a333c',
                    fillOpacity: 0.15,
                    editable: false,
                    geodesic: false

                });
                this.polygon.setMap(map);

                marker.setMap(map);

                // let drawingManager = new window.google.maps.drawing.DrawingManager({
                //     drawingControlOptions: {
                //         drawingModels: ['marker', 'polygon']
                //     }
                // })

                // drawingManager.setMap(map)

                // window.google.maps.event.addListener(marker, "dragend", e => {
                //     window.google.maps.geometry.poly.containsLocation(e.latLng, this.polygon ) ? console.log(true) : console.log(false);
                // });
            },
            async changeEstablishment(recordId) {
                if (recordId !== this.establishment.id) {
                    
                    this.establishment = find(this.establishments, {id: recordId})
                    await this.getRecord()
                    await this.initMap()
                }
            }
            
        }
    }
</script>